/* DEV AND UAT */

const BaseURLStrings = {
  doctorURL: 'https://nb5cbumh88.execute-api.eu-west-1.amazonaws.com/uat/',
  employerURL: 'https://3q15ecr9u9.execute-api.eu-west-1.amazonaws.com/uat/',
  sharedURL: 'https://uzh0h8isl2.execute-api.eu-west-1.amazonaws.com/uat/',
  employeeURL: 'https://xb5hlcvted.execute-api.eu-west-1.amazonaws.com/uat/',
  financialURL: 'https://uzh0h8isl2.execute-api.eu-west-1.amazonaws.com/uat/',
  userToken: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).signInUserSession.idToken.jwtToken : '',
  loggedInDateTime: localStorage.getItem('loggedInTime') ? JSON.parse(localStorage.getItem('loggedInTime')).datetime : '',
  userPool: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: 'eu-west-1:4404cc55-fdb4-4122-9dee-83f9e6f7de89',
    // REQUIRED - Amazon Cognito Region
    region: 'eu-west-1',
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: 'eu-west-1_FwSprNzZd',
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: '1o2h2ogn0ruptcgepct3ncpigr'
  },
  environment: 'UAT'
}; 

/***************************************************************************************************************************************/

/* PROD */


/* const BaseURLStrings = {
   doctorURL: 'https://o0fuq9a9kk.execute-api.eu-west-1.amazonaws.com/prod/',
   employerURL: 'https://evgg1rqs74.execute-api.eu-west-1.amazonaws.com/prod/',
   sharedURL: 'https://4xmnqlz6ih.execute-api.eu-west-1.amazonaws.com/prod/',
   employeeURL: 'https://gnznzn9aq2.execute-api.eu-west-1.amazonaws.com/prod/',
   financialURL: 'https://4xmnqlz6ih.execute-api.eu-west-1.amazonaws.com/prod/',
   userToken: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).signInUserSession.idToken.jwtToken : '',
   loggedInDateTime: localStorage.getItem('loggedInTime') ? JSON.parse(localStorage.getItem('loggedInTime')).datetime : '',
   userPool: {
     // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
     identityPoolId: 'eu-west-1:4404cc55-fdb4-4122-9dee-83f9e6f7de89',
     // REQUIRED - Amazon Cognito Region
     region: 'eu-west-1',
     // OPTIONAL - Amazon Cognito User Pool ID
     userPoolId: 'eu-west-1_FwSprNzZd',
     // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
     userPoolWebClientId: '1o2h2ogn0ruptcgepct3ncpigr'
   },
   environment: 'PROD'
 };  */


/***************************************************************************************************************************************/

export const URL_DOCTOR = BaseURLStrings.financialURL;       //
export const URL_EMPLOYER = BaseURLStrings.financialURL;   //
export const URL_SHARED = BaseURLStrings.sharedURL;       //
export const URL_EMPLOYEE = BaseURLStrings.financialURL;   //
export const URL_FINANCIAL = BaseURLStrings.financialURL;
export const USER_TOKEN = BaseURLStrings.userToken;
export const USER_POOL = BaseURLStrings.userPool;
export const ENV = BaseURLStrings.environment;
export const LOGGEDIN_DATETIME = BaseURLStrings.loggedInDateTime;